'use client'
// import storage from '@app/lib/storage'
// import * as utils from '@app/lib/utils'
import cookieCutter from 'js-cookie'
import { useParams, usePathname, useSearchParams } from 'next/navigation'
import useTranslation from 'next-translate/useTranslation'
import React, { useEffect, useState } from 'react'

import clsxm from '@/lib/clsxm'
import { useNavigationEvent } from '@/hooks/useNavigationEvent'

// import Select from 'react-select'
import BackButton from '@/components/BackButton'
import LanguageChange from '@/components/layout/LanguageChange'
import LoginButton from '@/components/layout/LoginButton'
import PageLoadingIndicator from '@/components/layout/PageLoadingIndicator'
import WebLink from '@/components/links/WebLink'
import LocationModal from '@/components/LocationModal'
import NextImage from '@/components/NextImage'

import LocationSelectSvg from '@/assets/location/location-select.svg'

import { LocationInfo } from '@/types'
// import LocationModal from '@/components/location-modal'

const isWebLinkActive = (name: string, pathname: string, params: { location?: string } | null) => {
  if (name === 'home' && params && params.location && pathname && pathname.includes(params.location)) {
    return true
  } else if (pathname && pathname.includes(name)) {
    return true
  }

  return false
}

const MenuLink = ({
  label,
  href,
  lang,
  isActive,
}: {
  label: string
  href: string
  lang: string
  isActive: boolean
}) => (
  <div className="inline-flex items-center">
    <WebLink
      href={href}
      nextLinkProps={{
        locale: lang,
        prefetch: false,
      }}
      className={clsxm(
        'w-full items-center whitespace-nowrap border-gray-300 py-4 text-center text-2xl font-medium lg:my-2 ',
        'xl:mr-3 xl:rounded-full xl:px-4 xl:py-[10px] xl:text-xl xl:font-normal',
        isActive
          ? 'xl:text-primary-700 rounded-full xl:border xl:border-gray-500 xl:bg-white'
          : 'xl:hover:text-primary-700',
      )}>
      {label}
    </WebLink>
  </div>
)

export default function Header({
  isUserLoggedIn,
  locationInfo,
}: // params,
{
  isUserLoggedIn: boolean
  locationInfo: LocationInfo
  // params: {
  //   lang: AppLocale
  //   location?: LocationSlug
  // }
}) {
  const { t, lang: webLang } = useTranslation()

  const params = useParams()
  const searchParams = useSearchParams()
  const pathname = usePathname() as string

  const [active, setActive] = useState(false)

  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)
  // const [cityDisplayName, setCityDisplayName] = useState(locationInfo.cityName || t(`Select a location`))

  const noheader = searchParams?.get('noheader')

  const cityDisplayName = locationInfo.cityName || ''
  const cityDisplayNameAbbr = locationInfo.abbrev || ''

  const lang = (params?.lang || cookieCutter.get('NEXT_LOCALE') || 'es') as string

  const isAuthPage =
    pathname &&
    (pathname.includes('/auth') || pathname.includes('/booking-summary') || pathname.includes('/booking-success'))
      ? true
      : false

  const handleClick = () => {
    setActive(!active)
  }

  // useEffect(() => {
  //   // if (active) {
  //   //   handleClick()
  //   // }

  //   if (params && params?.location && pathname && pathname.split('/').length > 3) {
  //     setShowBackBtn(true)
  //   } else {
  //     setShowBackBtn(false)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname, params])

  useNavigationEvent(() => {
    if (active) {
      handleClick()
    }
  })

  if (noheader) {
    return null
  }

  const homepageSlug = `/${lang}/${locationInfo.citySlug}`
  const isHomepage = `/${lang}${pathname}` === homepageSlug
  let showBackBtn = !isHomepage

  if (active) {
    showBackBtn = true
  }

  return (
    <>
      <PageLoadingIndicator />
      <nav
        className={clsxm(
          'flex flex-col xl:flex-row xl:justify-between',
          'border-b-text-600 sticky top-0 z-20 border-b bg-white shadow-md',
          'xl:h-auto',
          active && 'h-screen',
        )}>
        <div
          className={clsxm('flex items-center justify-between px-3 py-3', active && 'border-text-500 mt-3 border-b')}>
          <div>
            <WebLink
              href={homepageSlug}
              nextLinkProps={{
                locale: lang,
                prefetch: false,
              }}
              className={clsxm('inline-flex items-center', active && 'hidden')}>
              <NextImage
                src={require(`@/assets/logo.svg`)}
                alt="Vermut"
                className="h-[28px] w-[102px] xl:h-[52px] xl:w-[190px]"
              />
            </WebLink>
            <div className={clsxm('xl:hidden', !active && 'hidden')}>
              <div className="py-2" onClick={handleClick}>
                <div className="flex w-24 cursor-pointer rounded-full border border-gray-700 px-3 py-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18 10.875C18.4142 10.875 18.75 11.2108 18.75 11.625C18.75 12.0392 18.4142 12.375 18 12.375L18 10.875ZM4.71968 12.1553C4.42679 11.8624 4.42679 11.3876 4.71968 11.0947L9.49265 6.3217C9.78554 6.02881 10.2604 6.02881 10.5533 6.3217C10.8462 6.61459 10.8462 7.08947 10.5533 7.38236L6.31067 11.625L10.5533 15.8676C10.8462 16.1605 10.8462 16.6354 10.5533 16.9283C10.2604 17.2212 9.78554 17.2212 9.49265 16.9283L4.71968 12.1553ZM18 12.375L5.25001 12.375L5.25001 10.875L18 10.875L18 12.375Z"
                      fill="#3D3D3D"
                    />
                  </svg>
                  <span className="text-base font-medium">{t('Back')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={clsxm(active && 'hidden', 'xl:hidden')}>
            <button
              // href={`${pathname}?trigger=location`}
              // locale={lang}
              // prefetch={false}
              onClick={() => setIsLocationModalOpen(!isLocationModalOpen)}
              className="border-text-600  text-centerflex w-full flex-row items-center rounded-full border bg-white py-2 pl-2 pr-4 text-xl font-medium">
              <NextImage src={LocationSelectSvg} alt="Location Icon" className="inline" />
              <span>{cityDisplayNameAbbr}</span>
            </button>
          </div>
          {!isAuthPage && (
            <button
              className={clsxm(
                ' inline-flex rounded-full px-3 py-2 outline-none xl:hidden',
                'text-xl font-medium',
                !active && 'bg-secondary-50 shadow',
              )}
              onClick={handleClick}>
              {t('Menú')}
            </button>
          )}
        </div>

        <div className={clsxm(active ? 'block' : 'hidden xl:flex')}>
          <div className={clsxm(isAuthPage === true && 'hidden', isAuthPage === false && '', 'xl:flex xl:items-center')}>
            <div
              className={clsxm(
                isAuthPage === true && 'hidden',
                isAuthPage === false && 'flex flex-col xl:flex-row',
                '',
              )}>
              <MenuLink
                label={t('Inicio')}
                href={`/${lang}/${locationInfo.citySlug}`}
                lang={lang}
                isActive={isWebLinkActive('home', pathname, params)}
              />
              <MenuLink
                label={t('Comunidades B2B')}
                href={`/${lang}/communities-b2b`}
                lang={lang}
                isActive={isWebLinkActive('communities-b2b', pathname, params)}
              />
              <MenuLink
                label={t('Conviértete en Partner')}
                href={`/${lang}/become-partner`}
                lang={lang}
                isActive={isWebLinkActive('become-partner', pathname, params)}
              />

              <MenuLink
                label={t('Sobre Nosotros')}
                href={`/${lang}/about-us`}
                lang={lang}
                isActive={isWebLinkActive('about-us', pathname, params)}
              />

              <MenuLink
                label={t('Contacta')}
                href={`/${lang}/contact`}
                lang={lang}
                isActive={isWebLinkActive('contact', pathname, params)}
              />
            </div>

            <div className="mx-auto mt-9 flex w-full max-w-[250px] flex-col items-center xl:mt-0 xl:max-w-none xl:flex-row">
              <div className="w-full">
                <button
                  onClick={() => setIsLocationModalOpen(!isLocationModalOpen)}
                  className="border-text-600 flex w-full flex-row items-center rounded-full border bg-white py-2 pl-2 pr-4">
                  <NextImage src={LocationSelectSvg} alt="Location Icon" width={24} height={24} />
                  <span className="truncate whitespace-nowrap text-2xl font-medium xl:text-base">
                    {cityDisplayName}
                  </span>
                </button>
              </div>
              <div className="mt-9 w-full xl:ml-4 xl:mr-6 xl:mt-0">
                <LanguageChange />
              </div>
            </div>
            <div className="mx-auto mt-9 max-w-[250px] xl:ml-4 xl:mt-0 xl:pr-4">
              <LoginButton />
            </div>
          </div>
        </div>
      </nav>

      <LocationModal
        // locationInfo={locationInfo}
        isOpen={isLocationModalOpen}
        onClose={() => setIsLocationModalOpen(false)}
      />
    </>
  )
}
